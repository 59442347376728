import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Select, SelectOption, Box, Table } from "@nulogy/components";
import { config } from "../../playgrounds/select";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const Example = makeShortcode("Example");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Select`}</h1>
    <p>{`For making one selection from a large list of options.`}</p>
    <p>{`If you need to fetch options for the select as the interacts with the select, please use the `}<a parentName="p" {...{
        "href": "/async-select"
      }}>{`AsyncSelect`}</a>{`.`}</p>
    <Playground {...config} mdxType="Playground" />
    <h2>{`Variations`}</h2>
    <h3>{`MultiSelect`}</h3>
    <p>{`Give users the ability to select multiple options with the multiselect. Note that the value will now be represented as an Array rather than a string.`}</p>
    <Example componentName="select" scope={{
      Select
    }} example="Multi" mdxType="Example" />
    <h3>{`Replacing Inner Components`}</h3>
    <p>{`Using the `}<inlineCode parentName="p">{`components`}</inlineCode>{` prop you can augment the inner components of the select. See the following example of adding a component to each option in the dropdown:`}</p>
    <Example componentName="select" scope={{
      Select,
      SelectOption,
      Box
    }} example="CustomOptions" showEditor mdxType="Example" />
    <h3>{`List of All Replaceable components`}</h3>
    <p>{`Here is a list of all the available components that can be overidden and customized`}</p>
    <Table columns={[{
      label: "Object Key",
      dataKey: "keyName"
    }, {
      label: "Component Name",
      dataKey: "import"
    }, {
      label: "Description",
      dataKey: "description"
    }]} rows={[{
      keyName: "Option",
      import: "SelectOption",
      description: "Individual options in the select's dropdown"
    }, {
      keyName: "Control",
      import: "Control",
      description: "Wraps the select field"
    }, {
      keyName: "MultiValue",
      import: "SelectMultiValue",
      description: "The selected options in a muliselect select"
    }, {
      keyName: "ClearIndicator",
      import: "SelectClearIndicator",
      description: "The clear button in the multiselect"
    }, {
      keyName: "SelectContainer",
      import: "SelectContainer",
      description: "The wrapper around the whole field and dropdown"
    }, {
      keyName: "Menu",
      import: "SelectMenu",
      description: "The wrapper around the dropdown options"
    }, {
      keyName: "Input",
      import: "SelectInput",
      description: "The typeable input in the select"
    }]} mdxType="Table" />
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />
    <h2>{`Related Components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/async-select"
        }}>{`AsyncSelect`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      